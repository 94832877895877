@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Georgia", serif;
  }
}

@font-face {
  font-family: 'LexendExa';
  src: local('LexendExa'), url(../fonts/LexendExa-VariableFont_wght.ttf) format('truetype');
}
